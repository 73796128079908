<template>
  <div class="slick-carousel">
    <VueSlickCarousel ref="carousel" v-bind="options">
      <slot name="slides" />
      <template #customPaging>
        <div
          class="w-2 h-2 bg-gray-400 rounded-full border-4 border-transparent cursor-pointer carousel-nav-item"
        />
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      options: {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 990,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 1,
              //   arrows: false,
              dots: false,
              centerMode: true,
              centerPadding: '15px',
            },
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 1,
              //   arrows: false,
              dots: false,
              centerMode: true,
              centerPadding: '15px',
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.options.dots = true;
  },
};
</script>

  <style lang="scss">
  .slick-list {
    padding: 0 !important;
    padding-right: 15px !important;
    @media only screen and (max-width: 780px) {
      overflow: visible !important;
    }
  }
  @media only screen and (max-width: 780px) {
      .slick-arrow {
      display: none !important;
    }
  }
  .slick-carousel {
    .slick-dots {
      li {
        @apply mx-0;
        &.slick-active {
          .carousel-nav-item {
            @apply bg-blue-600;
          }
        }
      }
    }
  }
  /* The CSS here is extracted from 'vue-slick-carousel/dist/vue-slick-carousel-theme.css' bacause of a console error */
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: calc(50% - 16px);
    display: block;

    width: 32px;
    height: 32px;
    border-radius: 6px;
    z-index: 2;
    padding: 0;
    //   -webkit-transform: translate(0, -50%);
    //   -ms-transform: translate(0, -50%);
    //   transform: translate(0, -50%);
    cursor: pointer;
    box-shadow: 0 8px 8px 0 rgba(16, 23, 43, 0.03),
      0 1px 2px 0 rgba(10, 15, 37, 0.14);
    background-color: #fff;
    border-radius: 50%;

    //   color: transparent;
    //   border: none;
    //   outline: none;
    //   background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    cursor: pointer;
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0;
    cursor: auto;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
    cursor: auto;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    font-weight: 900;
    color: black;
  }

  .slick-prev {
    left: -5px;
  }
  [dir='rtl'] .slick-prev {
    right: -5px;
    left: auto;
  }

  .slick-prev:before {
    content: '';
    background-image: url('https://cdn.staging.parkos.com/assets/img/static/arrow.png') !important;
    width: 100%;
    height: 50%;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    filter: grayscale(1) saturate(0%) brightness(70%) contrast(1000%);
  }
  [dir='rtl'] .slick-prev:before {
    content: '' !important;
    display: block !important;
    background-image: url('https://cdn.staging.parkos.com/assets/img/static/arrow.png') !important;
    width: 100% !important;
    height: 50% !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(1) saturate(0%) brightness(70%) contrast(1000%);
  }

  .slick-next {
    right: -5px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: -5px;
  }
  .slick-next:before {
    content: '' !important;
    display: block !important;
    background-image: url('https://cdn.staging.parkos.com/assets/img/static/arrow.png') !important;
    width: 100% !important;
    height: 50% !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(1) saturate(0%) brightness(70%) contrast(1000%);
  }
  [dir='rtl'] .slick-next:before {
    content: '';
    background-image: url('https://cdn.staging.parkos.com/assets/img/static/arrow.png') !important;
    width: 100%;
    height: 50%;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    filter: grayscale(1) saturate(0%) brightness(70%) contrast(1000%);
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }
 [dir='rtl'] .slick-prev:hover:before, .slick-prev:hover:before,  [dir='rtl'] .slick-next:hover:before ,.slick-next:hover:before{
    filter:none;
  }
  </style>
