var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-sans",attrs:{"id":"airports"}},[_c('h2',{staticClass:"text-base font-cocogoose xs:text-xl md:text-3xl"},[_vm._v("\n    "+_vm._s(_vm.$i18n("general.our-airports"))+"\n  ")]),_vm._v(" "),(_vm.inactiveAirports.length)?_c('div',{staticClass:"mt-7 mb-5 inactive-airports-header"},[_c('div',{staticClass:"inline text-[14px] xs:text-base",domProps:{"innerHTML":_vm._s(
        _vm.$i18n('templates.reserve-now', {
          activeLocations: _vm.airports.length,
          merchants: _vm.totalParkings,
        })
      )}}),_vm._v(" "),_c('div',{staticClass:"inline text-[14px] xs:text-base",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.$i18n('templates.more-airports')))}})]):_vm._e(),_vm._v(" "),_vm._l((_vm.countriesSorted),function(country){return [_c('div',{key:country,staticClass:"mt-5 md:mt-10"},[_c('div',{staticClass:"flex place-content-between mb-4 md:mb-6"},[_c('h3',{staticClass:"text-[14px] xs:text-md md:text-xl font-cocogoose"},[_vm._v("\n          "+_vm._s(country)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"airports-container-no-scroll flex flex-nowrap xs:flex-wrap overflow-x-scroll xs:overflow-x-hidden xs:grid xs:grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-10 md:grid-cols-3 md:gap-12 lg:gap-8 lg:grid-cols-4"},[_vm._l((_vm.getAirports(country)),function(airport){return [_c('Airport',{key:airport.id,staticClass:"max-h-min",class:{
              'mr-8': _vm.iOSversion.version && _vm.iOSversion.version <= 14,
            },attrs:{"airport":airport}})]})],2)])]}),_vm._v(" "),_c('transition',{attrs:{"name":"inactiveAirports"}},[(_vm.inactiveAirports.length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"inactiveAirportsList",staticClass:"mt-12"},[_c('div',{staticClass:"mt-7 mb-2"},[_vm._v("\n        "+_vm._s(_vm.$i18n("templates.inactive-airports"))+"\n      ")]),_vm._v(" "),_c('ul',_vm._l((_vm.inactiveAirports),function(airport){return _c('li',{key:airport.id},[_c('a',{staticClass:"text-blue-600 font-bold text-[14px] xs:text-base",attrs:{"href":`/${airport.slug}/`}},[_vm._v(_vm._s(airport.name))])])}),0)]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }